// eslint-disable-next-line
import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";

import "./PostAd.css";
import { db, auth } from "../../lib/Firebase/firebase";
import { getDoc, doc } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import PostAdForm from "./PostAdForm/PostAdForm";


import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import useLocationHook from "../../lib/hooks/useLocationsHook/useLocationHook";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import Loading from "../Loading/Loading";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import MySelect from "../../Playables/MySelect/MySelect";

function PostAd() {

 
  const [loading, setLoading] = useState(true);

  const { closeSubmenu } = useGlobalContext();

  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = getDoc(doc(db, "users", auth.currentUser.uid)).then(
      (docSnap) => {
        if (docSnap.exists) {
          setUser(docSnap.data());
          // console.log(docSnap.data());
          setLoading(false);
        }
      }
    );
    return () => unsubscribe;
  }, []);

  if (loading) {
    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>Start Selling Now Bantumart Uganda</title>

          <link rel="canonical" href="/post_ad" />
          <meta property="description" content="start selling Now" />
          <meta property="og:description" content="start selling Now" />
          <meta
            property="title"
            content="start selling NowA very important title"
          />
        </Helmet>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>start selling Now</title>

          <link rel="canonical" href="/post_ad" />
          <meta property="description" content="start selling Now" />
          <meta property="og:description" content="start selling Now" />
          <meta
            property="title"
            content="start selling NowA very important title"
          />
        </Helmet>

        <main>
          <NavbarWrap />
          <div
            style={{
              overflowX: "hidden !important",
              width: "100% !important",
            }}
            onMouseOver={closeSubmenu}
          >
            <div className="post_showcase_banner ">
              <div className="container">
                <h1 className="hide">post</h1>
                <h3 className="white-text">Post Ad</h3>
                <h4 className="white-text">Select Category First</h4>
              </div>
            </div>

            <div className="container">
              <div>
              <PostAdForm user={user} />
               
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default PostAd;

