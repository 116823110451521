import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Uganda } from "../../lib/Data/Locations/Locations";

const MySelect = () => {
  const [selected, setSelected] = useState([]);
  const [divisions, setDivisions] = useState(null);

  const handleChange = (selectedOption) => {
    setSelected(selectedOption.district);
    //  setDivisions(selectedOption.divisions);
    const term = selectedOption.divisions;

    const options = term.map((name) => ({ value: name, label: name }));
    setDivisions(options);
    // console.log(`Option selected:`, selectedOption);
  };

  // console.log(divisions);

  // useEffect(() => {
  //   // const newData = { ...divisions };
  //   // console.log(newData);
  //   console.log(divisions);
  // }, [selected]);

  //console.log(selected.length);
  return (
    <div
      style={{
        margin: 100,
      }}
      className="row"
    >
      <h1>{selected}</h1>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4">
            <span>Select Name</span>
            <Select
              onChange={handleChange}
              getOptionLabel={(option) => `${option.district} `}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => {}}
              options={Uganda}
              isSearchable={true}
            />
            )
          </div>

          {selected.length !== 0 ? "sam" : "lev"}

          <Select options={divisions} />
        </div>
      </div>
    </div>
  );
};

export default MySelect;



const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid red',
    boxShadow: state.isFocused ? '0 0 0 1px red' : null,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'red' : null,
    color: state.isSelected ? 'white' : 'black',
  }),
};
