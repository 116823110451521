import React from "react";
import { Link } from "react-router-dom";
import NavBarTwo from "../../BusinessComponents/NavBarTwo/NavBarTwo";

function SiteMap() {
  return (
    <>
      <NavBarTwo />
      <div
        style={{
          paddingBottom: "300px",
          marginTop: 20,
        }}
      >
        <div className="container">
          <h3>Website Sitemap</h3>
          <div className="row">
            <div className="col l4">
              <div>
                <Link to="/">Home</Link>
              </div>
              <div>
                <a
                  style={{
                    color: "hsl(210, 36%, 96%)",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bantumart-nigeria-admin.web.app/"
                >
                  Admin
                </a>
              </div>

              <div>
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col l4">
              <h6>Major Categories</h6>
              <div>
                <a href="https://ng.bantumart.com/lb/vehicles">Vechcles</a>
              </div>
              <div>
                <a href="https://ng.bantumart.com/lb/properties">Properties</a>
              </div>
              <div>
                <Link to="/lb/electronics">Electronics</Link>
              </div>
              <div>
                <Link to="/lb/agriculture">Agriculture</Link>
              </div>
              <div>
                <Link to="/lb/phones_and_tablets">Phones And Tablets</Link>
              </div>
              <div>
                <Link to="/lb/services">Phones And Tablets</Link>
              </div>
              <div>
                <Link to="/lb/fashion">Phones And Tablets</Link>
              </div>
              <div>
                <Link to="/lb/health">Health</Link>
              </div>
              <div>
                <Link to="/lb/home_appliances">Home Appliances</Link>
              </div>
              <div>
                <Link to="/lb/home_appliances">Home Appliances</Link>
              </div>
            </div>

            <div className="col l4">
              <div>
                <h6>Account</h6>
                <div>
                  <a href="https://ng.bantumart.com/sign_in">Sign In</a>
                </div>
                <div>
                  <a href="https://ng.bantumart.com/sign_up">Sign Up</a>
                </div>

                <div>
                  <Link to="/post_ad">Post AD</Link>
                </div>
              </div>
            </div>
            <div className="col l4">
              <div>
                <h6>Company</h6>
                <div>
                  <Link to="/terms">Terms & Conditions</Link>
                </div>
                <div>
                  <Link to="/privacy"> Privacy Policy</Link>
                </div>
                <div>
                  <Link to="/about">About</Link>
                </div>
                <div>
                  <Link to="/jobs">Career</Link>
                </div>
                <div>
                  <a href="https://ng.bantumart.com/sign_up">Sign Up</a>
                </div>

                <div>
                  <Link to="/post_ad">Post AD</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteMap;
