import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";

import LocationFilter from "../../components/LocationFilter/LocationFilter";
import ThumbNailSildersTwo from '../../components/ThumbNailSiders/ThumbNailSliderTwo'

import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import "./MegaSearch.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import LaodingImg from "../../lib/images/loading.png";
import NoMoreData from "../../components/NoMoreData/NoMoreData";
import FilterBtn from "../../components/FilterBtn/FilterBtn";

function MegaSearch() {


  const { page, categoryUrl, label, subCategoryUrl } = useParams();
  const { closeSubmenu } = useGlobalContext();

  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");

  const [result, setResult] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);
  //filter ro
  const [orderFormat, setOrderFormat] = useState("desc");
  const [noDataFound, setNoDataFound] = useState(false);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      where("subcategory", "==", subCategoryUrl),
      // where("district", "==", district),
      orderBy("createdAt", orderFormat),
      limit(12)
    );

    onSnapshot(q, (snapshoot) => {
      if (snapshoot.size === 0) {
        // alert("collection is empty");
        setNoDataFound(true);
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        // console.log(snapshoot.docs);
        setNoDataFound(false);
        setLoading(true);
        // setTimeout(() => {

        const term = snapshoot.docs.map((doc) =>
          // console.log(doc.data())
          ({
            id: doc.id,
            data: doc.data(),
          })
        );

        setResult(term);
        setLoading(false);
        //get the last doc
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
      }
    });
    // eslint-disable-next-line
  }, [orderFormat, page, categoryUrl, subCategoryUrl]);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    setLoading(true);

    const q = query(
      NewData,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      where("subcategory", "==", subCategoryUrl),
      orderBy("createdAt", orderFormat),
      startAfter(lastDoc),

      limit(12)
    );

    getDocs(q).then((snapshoot) => {
      // setLoading(true);
      // setTimeout(() => {
      if (snapshoot.size === 0) {
        //  alert("No more data");
        setLoading(false);
        setCollectionIsEmpty(true);
        //setResults("no more data");
      } else {
        const term = snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setResult([...result, ...term]);
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
        setLoading(false);
      }
    });
  };



  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{subCategoryUrl} on bantumart</title>

        <link
          rel="canonical"
          href={`/mega/${page}/${categoryUrl}/${label}/${subCategoryUrl}`}
        />
        <meta
          property="description"
          content={` buy and sell ${subCategoryUrl} .  Are your looking for ${page} , ${categoryUrl} or ${subCategoryUrl} `}
        />
        <meta property="og:description" content="start selling Now" />
        <meta
          property="title"
          content="start selling NowA very important title"
        />
      </Helmet>
      <main>
        <NavbarWrap />

        <div onMouseOver={closeSubmenu}>
          <div className="levbitz_container_two ">
            <div className="mega_showcase_banner">
              <div>
                {
                  <h5 className="white-text">
                    <span>
                      <Link className="white-text" to={`/${categoryUrl}`}>
                        {categoryUrl.replace(/_/g, " ")}
                      </Link>
                    </span>
                    <span> &#x2192;</span>
                    <span>{subCategoryUrl.replace(/_/g, " ")}</span>
                  </h5>
                }

                <div className="">
                  <div className="row">
                    

                   

                  <FilterBtn onClick={toggleDrawer}/>

                    
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction="left"
                        lockBackgroundScroll
                        className='bla bla bla'
                        style={{
                          
                          paddingLeft:10,
                         paddingTop:50 

                        }}
                    >
                        {/*<button className="">Hello World</button>*/}
                          <div className="row">
                          <div className="col l6 s6">
                          <div>
                          <span
className="z-depth-2"
                          style={{
                            background: "#094c59",
                           padding:"1px 5px",
                            color: "white",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={toggleDrawer}
                          
                        >
                          Apply Filters
                        </span> 
                          </div>
                          </div>
                          <div className="col l6 s6">
                          <div>
                          
                          <span

                          className="z-depth-2"
                          style={{
                            background: "red",
                           padding:"1px 5px",
                            color: "white",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDistrict("");
                            setDivision("");
                          }}
                         
                        >
                          clear filter
                        </span></div>
                          </div>


<div className="col l12 s12">
<LocationFilter
                        
                        setDistrict={setDistrict}
                        setDivision={setDivision}
                        setOrderFormat={setOrderFormat}
                        district={district}
                        />
</div>
                          </div>


                        

                        
                  
             
                    </Drawer>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="levbitz_container_two">
            <div className="row">
              
              <div className="col l12 s12">
                <div>{noDataFound ? "No data" : ""}</div>
                <div>
                  {result && result.length > 0 ? (
                    result
                      .filter((item) => {
                        if (district === "") {
                          return item;
                        } else if (division === "") {
                          return item.data.district === district;
                        } else {
                          return (
                            item.data.district === district &&
                            item.data.division === division
                          );
                        }
                      })
                      .map((item) => {
                        return <ThumbNailSildersTwo item={item} />
                         
                        
                      })
                  ) : (
                    <>
                      <p>no data</p>
                      <h4>Try to filter buy you Distict</h4>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {loading ? (
                <>
                  <div className="center">
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Loading..
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <img src={LaodingImg} alt="" />
                    </h3>
                  </div>
                </>
              ) : collectionIsEmpty ? (
                <NoMoreData />
              ) : (
                <>
                  <p className="center">
                    <span
                      style={{
                        background: "coral",
                        padding: "10px",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={LoadMoreHandeler}
                    >
                      Load More Ads
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default MegaSearch;







