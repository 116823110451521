import React from "react";
import TopOne from "../../Business/BusinessComponents/TopOne/TopOne";
import SocialMedia from "../../Business/BusinessComponents/SocialMedia/SocialMedia";

import { Link } from "react-router-dom";
import Android from "../../lib/images/download/android.png";
import Apple from "../../lib/images/download/apple.svg";


function Footer() {
  return (
    <div
    className="white"
    style={{
      marginTop: "50px",
    }}
  >
    <TopOne />
    <footer className="page-footer ">
      <SocialMedia />
      <div className="container">
        <div className="row">
          <div className="col l6 s12">
            <h5 className="white-text ">
              <Link className="white-text" to="/">
                bantuMart
              </Link>
            </h5>
            <p className="grey-text text-lighten-4">
              Buy And Sell Free Classified Ads in Nigeria 🚀 . <br /> Cars
              ,Land , Houses ,Jobs ,Agricultral Pdts, Fashion , Electronics
              ,Real Estate ,Services ,Jobs ,Education ,Health ,Animals and
              more...
            </p>

            <div className="row">
              <div className="col l6">
                <div className="row">
                  <div className="col l6">
                    <div className="center">
                      <img
                        onClick={() =>
                          alert("The mob App is currently under development")
                        }
                        styles={{
                          width: "120px",
                        }}
                        src={Apple}
                        alt="ios application"
                      />
                    </div>
                  </div>

                  <div className="col l6">
                    <div className="center">
                      <a
                        href=" https://play.google.com/store/apps/details?id=com.bantumart.nigeria"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{
                            width: "120px",
                          }}
                          src={Android}
                          alt="android application"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col l3  s6  ">
            <h5 className="white-text">Links</h5>
            <ul>
              <li>
                <Link className="grey-text text-lighten-3" to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="grey-text text-lighten-3" to="/privacy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="grey-text text-lighten-3" to="/terms">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link className="grey-text text-lighten-3" to="/jobs">
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  className="grey-text text-lighten-3"
                  to="/frequently_asked_questions"
                >
                  Faqs
                </Link>
              </li>
            </ul>
          </div>
          <div className="col l3  s6  ">
            <h5 className="white-text">Links</h5>
            <ul>
              <li>
                <Link className="grey-text text-lighten-3" to="/">
                  About Us
                </Link>
              </li>
            
             
              <li>
                <Link className="grey-text text-lighten-3" to="/jobs">
                  Careers
                </Link>
              </li>
              <li>
                <Link className="grey-text text-lighten-3" to="/site-map">
                  Site Map
                </Link>
              </li>
              <li>
                <span
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                  }}
                >
                call Us:   
                {/*<BiPhoneCall style={{
                  marginTop:5
                }} />*/ }
                </span>
                <a
                  className="grey-text text-lighten-3"
                  href="tel:+256 759 430697"
                >
                  +256 759 430697
                </a>
              </li>
              <li>
              WhatsApp
              <a
                  className="grey-text text-lighten-3"
                  href="tel:+256 759 430697"
                >
                  +256 759 430697
                </a>
              </li>

              <li>
              
              <a className="grey-text text-lighten-3" href="mailto:support.bantumart.com" target="_blank" rel="noopener noreferrer">support.bantumart.com</a>
              
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          Verion 0.004 &copy;2023- {new Date().getFullYear()}
          <span className="grey-text text-lighten-4 right">
          
            <a
              href="https://africa.bantumart.com"
              target="_blank"
              rel="noopener noreferrer"
              className="white-text text-lighten"
            >
             Bantumart <sup>TM</sup>
            </a>
          </span>
        </div>
      </div>
    </footer>
  </div>
  );
}

export default Footer;
