

import { AdAge } from "../../lib/Data/Data";

import Select from "react-select";
import { LocationApi } from "../../lib/constants/constants";
import useLocationHook from "../../lib/hooks/useLocationsHook/useLocationsHook";



const LocationFilter  = ({setDistrict,setDivision,setOrderFormat,district})=>{


  const uganda = useLocationHook(LocationApi);

  console.log(uganda);

//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const [selectDivision , setSelectedDivision] = useState(null)

//   const handleLocationChange = useCallback(
//     (selectedOption) => {
//      setDistrict(selectedOption.district)
//     // setSelectedDivision(null)
  
  
//     },
//     []
//   );
//   <Select
// styles={customStyles}
// placeholder='Choose District'
//   onChange={handleLocationChange}
//   getOptionLabel={(option) => `${option.district} `}
//   getOptionValue={(option) => `${option}`}
//   isOptionSelected={(option) => {}}
//   options={uganda}
//   isSearchable={true}
// />

  return(
    <>
    <div style={{
      marginTop:10
    }}>



    <select
      style={{
        height: "30px",
        width: "200px",
      border:'1px #094c59 solid',
        textTransform: "lowercase",
      }}
      onChange={(e) => setDistrict(e.target.value)}
      className="browser-default  fabian_multiple_select text-white "
    >
      <option value="" disabled selected>
        Select State
      </option>
      {uganda.map((district) => {
        return (
          <option
            className="text-white"
            key={district.district}
            value={district.district}
          >
            {district.district}
          </option>
        );
      })}
    </select>
  </div>

  <div>
    {district ? (
      <div >
        <select
         
        style={{
          height: "28px",
          width: "200px",
          marginTop:10,
        border:'1px #094c59 solid',
          textTransform: "lowercase",
        }}
          onChange={(e) => setDivision(e.target.value)}
          className="browser-default  fabian_multiple_select"
        >
          <option disabled selected>
           Select Region
          </option>

          {uganda.filter((item) => {
            if (item.district === district) {
              return item;
            } else {
              return null;
            }
          }).map((item) => {
            if (item.divisions.length > 0) {
              return item.divisions.map((division, index) => {
                return (
                  <option key={index} value={division}>
                    {division}
                  </option>
                );
              });
            } else {
              return null;
            }
          })}
        </select>
      </div>
    ) : null}
  </div>
  {/*<div>
    <select
    style={{
      height: "28px",
      width: "200px",
      marginTop:10,
    border:'1px #094c59 solid',
      textTransform: "lowercase",
    }}
      onChange={(e) => setOrderFormat(e.target.value)}
      className="browser-default  fabian_multiple_select text-white "
    >
      <option value="" disabled selected>
        Date Posted
      </option>
      {AdAge.map((age) => {
        return (
          <option
            style={{
              textTransform: "capitalize",
            }}
            key={age.id}
            className="text-white"
            value={age.value}
          >
            {age.name}
          </option>
        );
      })}
    </select>
    </div>*/}

    </>
  )
}


export default LocationFilter




const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid red',
    boxShadow: state.isFocused ? '0 0 0 1px red' : null,
    marginTop:10,
    width:230,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'red' : null,
    color: state.isSelected ? 'white' : 'black',
  }),
};
