import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAA5QIeAnr8dj7GphjJZMqg6oBf5A42LFg",
  authDomain: "buntumart-nigeria.firebaseapp.com",
  projectId: "buntumart-nigeria",
  storageBucket: "buntumart-nigeria.appspot.com",
  messagingSenderId: "598659278510",
  appId: "1:598659278510:web:d77014bb4f2ccb1cfd6f3f",
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  db,
  storage,
  //GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
};
