import React, { useState, useEffect } from "react";
import { useCallback  } from "react";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";
import ThumbNailSilders from "../../components/ThumbNailSiders/ThumbNailSilders";
import { districts } from "../../lib/Data/Districts/Districts";
import { Uganda } from "../../lib/Data/Locations/Locations";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MegaCaroselsettings } from "../../lib/CaroselSetting/CaroseSetting";
import "./DistrictSearch.css";
import FilterBtn from "../../components/FilterBtn/FilterBtn";
import LocationFilter from "../../components/LocationFilter/LocationFilter";



import  { AdAge, Fulldisplay } from "../../lib/Data/Data";

import { LocationApi } from "../../lib/constants/constants";
import useLocationHook from "../../lib/hooks/useLocationsHook/useLocationHook";

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  deleteDoc,
  doc,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import LaodingImg from "../../lib/images/loading.png";
import ThumbNailSildersTwo from '../../components/ThumbNailSiders/ThumbNailSliderTwo'

function DistrictSearch() {
  const { name } = useParams();

  console.log(useParams());

  const { closeSubmenu } = useGlobalContext();

  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");

  const [result, setResult] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);



  const [orderFormat, setOrderFormat] = useState("desc");

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,
      where("active", "==", true),
      where("district", "==", name),

      orderBy("createdAt", "desc"),
      limit(12)
    );

    onSnapshot(q, (snapshoot) => {
      if (snapshoot.size === 0) {
        alert("collection is empty");
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        // console.log(snapshoot.docs);
        setLoading(true);
        // setTimeout(() => {

        const term = snapshoot.docs.map((doc) =>
          // console.log(doc.data())
          ({
            id: doc.id,
            data: doc.data(),
          })
        );

        setResult(term);
        setLoading(false);
        //get the last doc
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
      }
    });
  }, [name]);

  //console.log(result);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    // alert("Load more");

    setLoading(true);

    const q = query(
      NewData,
      where("active", "==", true),
      where("district", "==", name),

      orderBy("createdAt", "desc"),
      startAfter(lastDoc),

      limit(12)
    );

    getDocs(q).then((snapshoot) => {
      // setLoading(true);
      // setTimeout(() => {
      if (snapshoot.size === 0) {
        //  alert("No more data");
        setLoading(false);
        setCollectionIsEmpty(true);
        //setResults("no more data");
      } else {
        const term = snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setResult([...result, ...term]);
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
        setLoading(false);
      }
    });
  };


  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  return (
    <>
      <NavbarWrap />
      <div onMouseOver={closeSubmenu}>
        <div className="levbitz_container_two">
          <div className="mega_showcase_banner">
            <div>
              <h5 className="white-text"> Bantumart Ads In {name}</h5>

              <div className="">
                <div className="row">





                <FilterBtn onClick={toggleDrawer}/>

                    
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction="left"
                    lockBackgroundScroll
                    className='bla bla bla'
                    style={{
                      
                      paddingLeft:10,
                     paddingTop:50 ,
                     overflowY:"scroll",
overflowX:"hidden",

                    }}
                >
                    {/*<button className="">Hello World</button>*/}
                      <div className="row">
                      <div className="col l6 s6">
                      <div>
                      <span
className="z-depth-2"
                      style={{
                        background: "#094c59",
                       padding:"1px 5px",
                        color: "white",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={toggleDrawer}
                      
                    >
                      Apply 
                    </span> 
                      </div>
                      </div>
                      <div className="col l6 s6">
                      <div>
                      
                      <span

                      className="z-depth-2"
                      style={{
                        background: "red",
                       padding:"1px 5px",
                        color: "white",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDistrict("");
                        setDivision("");
                      }}
                     
                    >
                      clear filter 
                    </span></div>
                      </div>


<div className="col l12 s12">

<SubLocationFilter
name={name}
setDivision={setDivision}
/>



<Fulldisplay/>

</div>
                      </div>


                    

                    
              
         
                </Drawer>
                  

                 
                  <div className="col l4">
                    <div
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <button
                        onClick={() => {
                          setDistrict("");
                          setDivision("");
                        }}
                        className="btn"
                      >
                        clear filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
            }}
            className="row green "
          >
            {/*<h5>{categoryUrl}</h5>*?}
          {/*<h5>{label}</h5>*/}
          </div>
        </div>
        <div className="levbitz_container_two">
          <div className="row">
            {result && result.length > 0 ? (
              result
                .filter((item) => {
                  if (name === "") {
                    return item;
                  } else if (division === "") {
                    return item.data.district === name;
                  } else {
                    return (
                      item.data.district === name &&
                      item.data.division === division
                    );
                  }
                })
                .map((item) => {
                  const { images, title, price, id } = item.data;
                  return <ThumbNailSildersTwo item={item} />;
                })
            ) : (
              <>
                <p>no data</p>
                <h4>Try to filter buy you Distict</h4>
              </>
            )}
          </div>

          <div className="row">
            {loading ? (
              <>
                <div className="center">
                  <h3
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Loading..
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <img src={LaodingImg} alt="" />
                  </h3>
                </div>
              </>
            ) : collectionIsEmpty ? (
              <div>
                <h3 className="center"> Opps No more data</h3>
              </div>
            ) : (
              <>
                <p className="center">
                  <span
                    style={{
                      background: "coral",
                      padding: "10px",
                      color: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={LoadMoreHandeler}
                  >
                    Load More Ads
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DistrictSearch;











const SubLocationFilter  = ({setDistrict,setDivision,setOrderFormat,district ,name})=>{


  const uganda = useLocationHook(LocationApi);


  return(
    <>


  <div>
    {name ? (
      <div >
        <select
         
        style={{
          height: "28px",
          width: "200px",
          marginTop:10,
        border:'1px #094c59 solid',
          textTransform: "lowercase",
        }}
          onChange={(e) => setDivision(e.target.value)}
          className="browser-default  fabian_multiple_select"
        >
          <option disabled selected>
            Division
          </option>

          {uganda.filter((item) => {
            if (item.district === name) {
              return item;
            } else {
              return null;
            }
          }).map((item) => {
            if (item.divisions.length > 0) {
              return item.divisions.map((division, index) => {
                return (
                  <option key={index} value={division}>
                    {division}
                  </option>
                );
              });
            } else {
              return null;
            }
          })}
        </select>
      </div>
    ) : null}
  </div>
  <div>
    <select
    style={{
      height: "28px",
      width: "200px",
      marginTop:10,
    border:'1px #094c59 solid',
      textTransform: "lowercase",
    }}
      onChange={(e) => setOrderFormat(e.target.value)}
      className="browser-default  fabian_multiple_select text-white "
    >
      <option value="" disabled selected>
        Date Posted
      </option>
      {AdAge.map((age) => {
        return (
          <option
            style={{
              textTransform: "capitalize",
            }}
            key={age.id}
            className="text-white"
            value={age.value}
          >
            {age.name}
          </option>
        );
      })}
    </select>
  </div>
  <div
  style={{
    marginTop:10,
  }}
  />

    </>
  )
}



