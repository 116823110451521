import React, { useState, useEffect } from "react";
import Android from "../../lib/images/download/android.png";
import "./AlertModal.css";

function AlertModal() {
  const [isVisibile, setIsVisibile] = useState(true);
  useEffect(() => {
    //check local storege
    const visibilityChecker = localStorage.getItem("pop_status");
    //console.log(visibilityChecker);
    if (visibilityChecker === null) {
      setIsVisibile(true);
    } else if (visibilityChecker === "1") {
      setIsVisibile(false);
    }
  }, []);
  // // if (!isVisibile) return null;

  const onClickHandler = () => {
    setIsVisibile(false);
    localStorage.setItem("pop_status", 1);
  };

  return isVisibile ? (
    <>
      <div className="myAlertWrap  ">
        <div>
          <div className="container">
            <div className="row">
              <div className="col l3"></div>
              <div className="col l6">
                <div
                  style={{
                    // border: "1px solid #094c59",
                    borderRadius: "10px",
                  }}
                  className=" alert-modal"
                >
                  <div
                    style={{
                      padding: "10px",
                    }}
                  >
                    <h6 className="center white-text">Hello Android Users</h6>
                    <p className="white-text">
                      We proudly announce the launch of our Android Application.
                    </p>
                    <p className="white-text">
                      For Android Users you can now download the bantumart
                      Application for better experience
                    </p>

                    {/*<p className="white-text">
                      Sell For Free .Increase your sales by 15% . Sell with just
                      a click on Bantumart Free Classified Ad Platform
                  </p>*/}
                    <p className="white-text">
                      <a
                        className="white-text"
                        href=" https://play.google.com/store/apps/details?id=com.bantumart.nigeria"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click Here to Download the APP
                      </a>
                    </p>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div
                        className="transparent"
                        // style={{
                        //   background: "#094c59",
                        //   color: "white",
                        //   outline: "none",
                        //   border: "none",
                        //   borderRadius: "5px",
                        //   padding: " 5px 10px",
                        // }}
                        // onClick={onClickHandler}
                      >
                        <a
                          href=" https://play.google.com/store/apps/details?id=com.bantumart.nigeria"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{
                              width: "120px",
                            }}
                            src={Android}
                            alt="android application"
                          />
                        </a>
                      </div>
                      <span
                        className="z-depth-2"
                        style={{
                          // color: "#094c59",
                          background: "white",
                          height: 22,
                          marginTop: 10,
                          // outline: "none",
                          // border: "none",
                          borderRadius: "5px",
                          padding: " 0px 2px 5px 5px",
                          // fontSize: "13px",
                        }}
                        onClick={onClickHandler}
                      >
                        No Stay On the Web
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col l3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default AlertModal;
