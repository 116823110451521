import React, { useState } from "react";

import ActiveAds from "./MyProfileAdComponents/ActiveAds/ActiveAds";
import InActiveAds from "./MyProfileAdComponents/InActiveAds/InActiveAds";
import { AuthContext } from "../../lib/context/AuthContext/auth";

import "./MyAds.css";

function MyAds() {
  return (
    <div className="container">
      <div className="row">
        <div className="col l12 s12">
          <div>
            <h4 style={{ fontSize: 18 }}>Your Ads</h4>

            <Tab  />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAds;

const Tab = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };
  return (
    <div className="levbitz_container">
      <div className="Tabs">
        <ul className="tab_header">
          <li
            className={activeTab === "tab1" ? "active" : ""}
            onClick={handleTab1}
          >
            Active Ads
          </li>
          <li
            className={activeTab === "tab2" ? "active" : ""}
            onClick={handleTab2}
          >
            Inactive Ads
          </li>
        </ul>

        <div className="outlet">
          {activeTab === "tab1" ? <ActiveAds/> : <InActiveAds />}
        </div>
      </div>
    </div>
  );
};
