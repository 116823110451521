export const Uganda = [
  {
    district: "Abuja (FCT) State",
    divisions: [
      "Central Business District",
      "Gwarinpa",
      "Kubwa",
      "Wuse",
      "Wuse 2",
      "Abaji",
      "Apo District",
      "Asokoro",
      "Bwari",
      "Dakibiyu",
      "Dakwo District",
      "Dei-Dei",
      "Duboyi",
      "Durumi",
      "Dutse-Alhaji",
      "Gaduwa",
      "Galadimawa",
      "Garki 1",
      "Garki 2",
      "Gudu",
      "Guzape District",
      "Gwagwa",
      "Gwagwalada",
      "Idu Industrial",
      "Jabi",
      "Jahi",
      "Jikwoyi",
      "Jiwa",
      "Kabusa",
      "Kado",
      "Karmo",
      "Karshi",
      "Karu",
      "Katampe",
      "Kaura",
      "Kpeyegyi",
      "Kuchigoro",
      "Kuje",
      "Kurudu",
      "Kwali",
      "Lokogoma",
      "Lugbe District",
      "Mabushi",
      "Maitama",
      "Mararaba",
      "Masaka",
      "Mbora",
      "Mpape",
      "Nyanya",
      "Okanje",
      "Orozo",
      "Pyakasa",
      "Sabo Gida",
      "Utako",
      "Wumba",
      "Wuye",
      "Zuba",
    ],
  },
  {
    district: "Lagos State",
    divisions: [
      "Ajah",
      "Alimosho",
      "Ikeja",
      "Ojo",
      "Surulere",
      "Abule Egba",
      "Agbara-Igbesan",
      "Agboyi/Ketu",
      "Agege",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Egbe Idimu",
      "Ejigbo",
      "Eko Atlantic",
      "Epe",
      "Gbagada",
      "Ibeju",
      "Ifako-Ijaiye",
      "Ikorodu",
      "Ikotun/Igando",
      "Ikoyi",
      "Ilashe",
      "Ilupeju",
      "Ipaja",
      "Isolo",
      "Kosofe",
      "Lagos Island (Eko)",
      "Lekki",
      "Magodo",
      "Maryland",
      "Mushin",
      " Ogba",
      "Ogudu",
      "Ojodu",
      "Ojota",
      "Orile",
      "Oshodi",
      " Shomolu",
      "Tarkwa Bay Island",
      "Victoria Island",
      "Yaba",
    ],
  },
  {
    district: "Ogun State",
    divisions: [
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Ijebu Ode",
      "Obafemi-Owode",
      "Sagamu",
      "Abeokuta North",
      "Ayetoro",
      "Ewekoro",
      "Ifo",
      "Ijebu",
      "Ikenne",
      "Ilaro",
      "Imeko Afon",
      "Ipokia",
      "Iseri",
      "Odeda",
      "Odogbolu",
      "Ogun Waterside",
      "Pakuro",
      "Remo North",
    ],
  },
  {
    district: "Oyo State",
    divisions: [
      "Akinyele",
      "Egbeda",
      "Ibadan",
      "Ido",
      "Oluyole",
      "Afijio",
      "Atiba",
      "Atisbo",
      "Ayete",
      "Eruwa",
      "Igbo Ora",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Ona-Ara",
      "Orelope",
      "Ori Ire",
      "Oyo",
      "Saki East",
      "Saki West",
      "Surulere-Oyo",
    ],
  },
  {
    district: "Rivers State",
    divisions: [
      "Eleme",
      "Ikwerre",
      "Obio-Akpor",
      "Oyigbo",
      "Port-Harcourt",
      "Abua/Odual",
      "Ahoada",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Emohua",
      "Etche",
      "Gokana",
      "Khana",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Tai",
    ],
  },
  {
    district: " Abia State",
    divisions: [
      "Aba North",
      "Aba South",
      "Umuahia",
      "Arochukwu",
      "Bende",
      "Ikwuano",
      "Isiala Ngwa",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohafia",
      "Osisioma Ngwa",
      "Ugwunagbo",
      "Ukwa",
      "Umu Nneochi",
    ],
  },
  {
    district: " Adamawa State",
    divisions: [
      "Yola North",
      "Yola South",
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
    ],
  },
  {
    district: " Akwa Ibom State",
    divisions: [
      "Abak",
      "Eket",
      "Ikot Ekpene",
      "Oron",
      "Uyo",
      "Eastern Obolo",
      "Esit-Eket",
      "Essien Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono Ibom",
      "Ika",
      "Ikono",
      "Ikot Abasi",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat Enin",
      "Nsit Atai",
      "Nsit Ibom",
      "Nsit Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oruk Anam",
      "Udung Uko",
      "Ukanafun",
      "Uquo-Ibeno",
      "Uruan",
      "Urue-Offong/Oruko",
    ],
  },
  {
    district: " Anambra State",
    divisions: [
      "Awka",
      "Idemili",
      "Nnewi",
      "Onitsha",
      "Aghamelu",
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Ihiala",
      "Njikoka",
      "Ogbaru",
      "Orumba",
      "Oyi",
    ],
  },
  {
    district: "Bauchi State",
    divisions: [
      "Bauchi LGA",
      "Bogoro",
      "Alkaleri",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },

  {
    district: "Bayelsa State",
    divisions: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenagoa",
    ],
  },
  {
    district: "Benue State ",
    divisions: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    district: "Borno State",
    divisions: [
      "Abadam",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biua",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya Kusar",
      "Mafa",
      "Magumeri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    district: "Cross River State",
    divisions: [
      "Abi",
      "Akpabuyo",
      "Akpamkpa",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakuur",
      "Yala",
    ],
  },
  {
    district: "Delta State",
    divisions: [
      "Aniocha North",
      "Aniocha South",
      "Bomadi",
      "Burutu",
      "Ethiope East",
      "Ethiope West",
      "Ika North East",
      "Ika South",
      "Isoko North",
      "Isoko South",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli North",
      "Ughelli South",
      "Ukwuani",
      "Uvwie",
      "Warri North",
      "Warri South",
      "Warri South West",
    ],
  },
  {
    district: " Ebonyi State",
    divisions: [
      "Abakaliki",
      "Afikpo North",
      "Afikpo South",
      "Ebonyi",
      "Ezza North",
      "Ezza South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    district: "Edo State",
    divisions: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba-Okha",
      "Orhionmwon",
      "Oredo",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
  },
  {
    district: " Ekiti State",
    divisions: [
      "Ado Ekiti",
      "Ido-Osi",
      "Ikere",
      "Ikole",
      "Ilawe",
      "Aiyekire (Gbonyin)",
      "Aramoko",
      "Efon",
      "Emure",
      "Ijero",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise/Orun",
      "Moba",
      "Omuo",
      "Oye",
    ],
  },
  {
    district: " Enugu State",
    divisions: [
      "Enugu",
      "Nkanu West",
      "Nsukka",
      "Udi",
      "Aninri",
      "Awgu",
      "Ezeagu",
      "Igbo Eze South",
      "Igbo-Etiti",
      "Igbo-Eze North",
      "Isi-Uzo",
      "Nkanu East",
      "Oji-River",
      "Udenu",
      "Uzo-Uwani",
    ],
  },
  {
    district: "Imo State",
    divisions: [
      "Ikeduru",
      "Mbaitoli",
      "Okigwe",
      "Orlu",
      "Owerri",
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ezinihitte Mbaise",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Isiala Mbano",
      "Isu",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Onuimo",
      "Orsu",
      "Oru",
    ],
  },

  {
    district: "Jigawa State",
    divisions: [
      "Dutse",
      "Garki",
      "Auyo",
      "Babura",
      "Biriniwa",
      "Buji",
      "Gagarawa",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri Kasamma",
      "Kiyawa",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    district: "Kaduna State",
    divisions: [
      "Chikun",
      "Igabi",
      "Kaduna",
      "Zaria",
      "Birnin-Gwari",
      "Giwa",
      "Ikara",
      "Jaba",
      "Jema'a",
      "Kachia",
      "Kagarko",
      "Kajuru",
      "Kaura-Kaduna",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sanga",
      "Soba",
      "Zango-Kataf",
    ],
  },
  {
    district: "Kano State",
    divisions: [
      "Fagge",
      "Kano Municipal",
      "Nasarawa",
      "Tarauni",
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Gabasawa",
      "Garko",
      "Garun Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    district: "Katsina State",
    divisions: [
      "Danja",
      "Daura",
      "Katsina",
      "Zango",
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan Musa",
      "Dandume",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Kurfi",
      "Kusada",
      "Mai'adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
    ],
  },

  {
    district: "Kebbi State",

    divisions: [
      "Birnin Kebbi",
      "Jega",
      "Zuru",
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Kalgo",
      "Koko/Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
    ],
  },
  {
    district: "Kogi State",
    divisions: [
      "Lokoja",
      "Okene",
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igala Mela",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi LGA",
      "Koton Karfe",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
  },
  {
    district: "Kwara State",

    divisions: [
      "Ilorin East",
      "Ilorin South",
      "Ilorin West",
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti-Kwara",
      "Ifelodun-Kwara",
      "Irepodun-Kwara",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    district: "Nasarwa State",
    divisions: [
      "Karu-Nasarawa",
      "Keffi",
      "Lafia",
      "Akwanga",
      "Awe",
      "Doma",
      "Keana",
      "Kokona",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi-Nasarawa",
      "Toto",
      "Wamba",
    ],
  },
  {
    district: "Niger State",
    divisions: [
      "Bida",
      "Bosso",
      "Chanchaga",
      "Minna",
      "Suleja",
      "Agaie",
      "Agwara",
      "Borgu",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Muya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    district: "Ondo State",
    divisions: [
      "Akure",
      "IjuItaogbolu",
      "Okitipupa",
      "Ondo  Ondo State",
      "Owo",
      "Akungba",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ikare Akoko",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Isua",
      "Odigbo",
      "Oka",
      "Okeagbe",
      "Okeigbo",
      "Ose",
    ],
  },
  {
    district: "Osun State",

    divisions: [
      "Aiyedade",
      "Aiyedire",
      "Atakumosa East",
      "Atakumosa West",
      "Boluwaduro",
      "Boripe",
      "Egbedore",
      "Ede",
      "Ejigbo",
      "Ife Central",
      "Ife East",
      "Ife North",
      "Ife South",
      "Ifedayo",
      "Ifelodun",
      "Ila",
      "Ilesa East",
      "Ilesa West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola-Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    district: "Plateau State",

    divisions: [
      "Barikin Ladi",
      "Bassa",
      "Bokkos",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Kanke",
      "Langtang North",
      "Langtang South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    district: "Sokoto State",

    divisions: [
      "Illela",
      "Sokoto North",
      "Sokoto South",
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Isa",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    district: "Taraba State",

    divisions: [
      "Jalingo",
      "Takum",
      "Wukari",
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Ussa",
      "Yorro",
      "Zing",
    ],
  },
  {
    district: "Yobe State",

    divisions: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmua",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    district: "Zamfara State",

    divisions: [
      "Anka",
      "Bakura",
      "Birnin Magaji",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Kaura Namoda",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
