import React from 'react'

function FilterBtn({onClick}) {
  return (
    <span  style={{
      background: "coral",
      marginTop:10,
      padding: "5px 20px",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      display:"flex",
      justifyContent:'center',
      width:150
    }} onClick={onClick}>
    


<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"  
fill="white"
width={20}
aria-hidden="true" role="presentation" focusable="false"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
         
    
    <span>
    Apply Filters
    </span>
    </span>
  )
}

export default FilterBtn
