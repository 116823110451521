import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { useParams } from "react-router-dom";
import { db } from "../../lib/Firebase/firebase";
import ThumbNailSildersTwo from "../../components/ThumbNailSiders/ThumbNailSliderTwo";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FilterBtn from "../../components/FilterBtn/FilterBtn";
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import { Uganda } from "../../lib/Data/Locations/Locations";

import "./Categories.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore";
import NavbarWrap from "../../components/Navbar/NavbarWrap";
import sublink from "../../lib/Data/Data";
import LocationFilter from "../../components/LocationFilter/LocationFilter";

import LoadMoreAdsButton from "../../SubComponents/LoadMoreAdsButton/LoadMoreAdsButton";
import LoadMoreAnime from "../../SubComponents/LoadMoreAnime/LoadMoreAnime";

function Categories() {
  const { categoryUrl } = useParams();


  //console.log(useParams());



  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  const [orderFormat, setOrderFormat] = useState("desc");
  const { closeSubmenu } = useGlobalContext();



  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");

  const [result, setResult] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [collectionIsEmpty, setCollectionIsEmpty] = useState(false);

  const [uniqueFilters, setUniqueFilters] = useState([]);
  useEffect(() => {
    if (categoryUrl === "vehicles") {
      setUniqueFilters(sublink[0].links);
    } else if (categoryUrl === "properties") {
      setUniqueFilters(sublink[1].links);
    } else if (categoryUrl === "electronics") {
      setUniqueFilters(sublink[2].links);
    } else if (categoryUrl === "agriculture") {
      setUniqueFilters(sublink[3].links);
    } else if (categoryUrl === "phones_and_tablets") {
      setUniqueFilters(sublink[4].links);
    } else if (categoryUrl === "bikes_and_scooters") {
      setUniqueFilters(sublink[5].links);
    } else if (categoryUrl === "services") {
      setUniqueFilters(sublink[6].links);
    } else if (categoryUrl === "fashion") {
      setUniqueFilters(sublink[7].links);
    } else if (categoryUrl === "construction") {
      setUniqueFilters(sublink[8].links);
    } else if (categoryUrl === "health") {
      setUniqueFilters(sublink[9].links);
    } else if (categoryUrl === "home_appliances") {
      setUniqueFilters(sublink[10].links);
    }
  }, [categoryUrl]);

  const checckerRef = collection(db, "ads");

  useEffect(() => {
    const q = query(
      checckerRef,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      // where("subcategory", "==", subCategoryUrl),
      // where("district", "==", district),
      orderBy("createdAt", "desc"),
      limit(12)
    );

    onSnapshot(q, (snapshoot) => {
      if (snapshoot.size === 0) {
        //alert("collection is empty");
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        // console.log(snapshoot.docs);
        setLoading(true);
        // setTimeout(() => {

        const term = snapshoot.docs.map((doc) =>
          // console.log(doc.data())
          ({
            id: doc.id,
            data: doc.data(),
          })
        );

        setResult(term);
        setLoading(false);
        //get the last doc
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
      }
    });
    // eslint-disable-next-line
  }, []);

  const NewData = collection(db, "ads");

  const LoadMoreHandeler = () => {
    setLoading(true);

    const q = query(
      NewData,
      where("active", "==", true),
      where("category", "==", categoryUrl),
      // where("category", "==", categoryUrl),

      orderBy("createdAt", "desc"),
      startAfter(lastDoc),

      limit(12)
    );

    getDocs(q).then((snapshoot) => {
      if (snapshoot.size === 0) {
        setLoading(false);
        setCollectionIsEmpty(true);
      } else {
        const term = snapshoot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setResult([...result, ...term]);
        setLastDoc(snapshoot.docs[snapshoot.docs.length - 1]);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title> buy and sell {categoryUrl} on bantumart</title>

        <link rel="canonical" href={`/${categoryUrl}`} />
        <meta
          property="description"
          content={`
          start selling ${categoryUrl} on bantumart
           , buy  ${categoryUrl} on bantumart , 
           new ${categoryUrl} on bantumart ,  secondhand ${categoryUrl} on bantumart
           `}
        />

        <meta
          property="og:description"
          content={`
          start selling ${categoryUrl} on bantumart
           , buy  ${categoryUrl} on bantumart , 
           new ${categoryUrl} on bantumart ,  secondhand ${categoryUrl} on bantumart
           `}
        />
        <meta
          property="title"
          content={`buy and sell ${categoryUrl} on bantumart`}
        />

        {/*facebook*/}
        <meta property="og:type" content="website"></meta>

        <meta
          property="og:title"
          content="bantumart Deals Nigeria | Free Classified Ads | Buy & sell online"
        ></meta>
        <meta
          property="og:image"
          content="https://bantumart.com/favicon.ico"
        ></meta>
        <meta property="og:url" content="https://bantumart.com"></meta>
        {/*facebook*/}
      </Helmet>
      <NavbarWrap />
      <div onMouseOver={closeSubmenu}>
        
        <div className="levbitz_container_two ">
          <div className="category_mega_showcase_banner">
            <div>
              <div className="">
                <div className="row">

               <div className="col l7 s12">
               <h5 style={{
                textTransform:"capitalize",
                marginBottom:10,
               }} className="white-text">
               {`
               ${categoryUrl} on bantumart
                , buy and sell  ${categoryUrl} on bantumart 
              
                `}
             </h5>

             <FilterBtn onClick={toggleDrawer}/>
              
               </div>
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction="left"
                    lockBackgroundScroll
                    className='bla bla bla'
                    style={{
                      overflowX:"scroll",
                      paddingLeft:10,
                     paddingTop:50 

                    }}
                >
                    {/*<button className="">Hello World</button>*/}
                      <div className="row">
                      <div className="col l6 s6">
                      <div>


                      
                      <span
className="z-depth-2"
                      style={{
                        background: "#094c59",
                       padding:"1px 5px",
                        color: "white",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={toggleDrawer}
                      
                    >
                      Apply Filters
                    </span> 
                      </div>
                      </div>
                      <div className="col l6 s6">
                      <div>
                      
                      <span

                      className="z-depth-2"
                      style={{
                        background: "red",
                       padding:"1px 5px",
                        color: "white",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDistrict("");
                        setDivision("");
                      }}
                     
                    >
                      clear filter
                    </span></div>
                      </div>


<div className="col l12 s12">
<LocationFilter
                    
                    setDistrict={setDistrict}
                    setDivision={setDivision}
                    setOrderFormat={setOrderFormat}
                    district={district}
                    />
</div>
<div className="col l12 s12">

<h6 className="black-text">Categories</h6>

{uniqueFilters.map((item) => {
  return (
  
      <div style={{
        height:35,
        marginBottom:10,
       
      }}>
       <Link
        
        className=" truncate "
          to={`/mega/${categoryUrl}/${item.subCategoryUrl}`}


          style={{
            fontWeight: "bold",
            color:"#094c59",
          }}
        >
          {item.label} &#x2192;
        </Link>
      </div>
       
   

  );
})}
</div>
                      </div>


                    

                    
              
         
                </Drawer>
                 
            

                  {/*<div className="col l3">
                    <div
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <button
                        onClick={() => {
                          setDistrict("");
                          setDivision("");
                        }}
                        className="btn red"
                      >
                        clear filter
                      </button>
                    </div>
                      </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "10px",
            }}
            className="row green "
          ></div>
        </div>
        <div className="levbitz_container_two">
          <div className="row">
         
            <div className="col l12 m12 s12">
              <div>
                {result && result.length > 0 ? (
                  result
                    .filter((item) => {
                      if (district === "") {
                        return item;
                      } else if (division === "") {
                        return item.data.district === district;
                      } else {
                        return (
                          item.data.district === district &&
                          item.data.division === division
                        );
                      }
                    })
                    .map((item, index) => {
                      return <ThumbNailSildersTwo key={index} item={item} />;
                    })
                ) : (
                  <>
                    <p>no data</p>
                    <h4>Try to filter buy you Distict</h4>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            {loading ? (
              <LoadMoreAnime />
            ) : collectionIsEmpty ? (
              <div>
                <h3 className="center"> Opps No more data</h3>
              </div>
            ) : (
              <LoadMoreAdsButton onClick={LoadMoreHandeler} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
